import React, {useState} from "react";
import styled from "styled-components";
import { Link } from "gatsby";


import Button from "./button";

const Card = styled.div`
    background-color: #fff;
    width: 100%;
    box-shadow: 0 2px 4px rgba(60,66,68,.25);
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;


    h3 {
        font-size: 1.2rem;
        margin: 0;
        font-weight: 500;
        font-family: 'Rufina', serif;
    }

    h4 {
        font-size: 0.95rem;
        margin: 10px 0 0 0;
        font-weight: 500;
        color: #a2a2a2;
    }

    button {
        width: 100%;
        display: inline-block;
    }

    a {
        width: 20%;
        display: inline-block;
    }

    
    p {
        width: 100%;
        display: inline-block;
        height: 30px;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {

        flex-wrap: wrap;
        flex-direction: row-reverse;

        p {
            width: 100%;
            display: block;
            height: 60px;
        }


        a {
            width: 40%;
            display: block;
        }

        button {
            width: 100%;
            padding: 5px 15px;
        }
    }
`;

const Container = styled.div`
    width: 100%;
    background-color: #F9FAFE;

    h1 {
        font-size: 2rem;
        margin: 0 0 20px 0px;
        font-weight: 500;
        font-family: 'Rufina', serif;
        text-align: center;
    }
`

const Wrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
    flex-wrap: wrap;
`


const FormField = styled.div`

    position: relative;
    padding: 30px 0;

    input {
        width: 70%;
        position: absolute;
        right: 0;
        padding: 5px 10px;
        border: 1px solid #b8b8b8;
        border-radius: 3px;
    }

    input:disabled {
        padding: 5px 10px;
        border: 1px solid #b8b8b8;
        border-radius: 3px;
        background: #fff;
        color: #000;
    }

    select {
        width: 70%;
        position: absolute;
        right: 0;
        padding: 5px 10px;
    }

    textarea {
        width: 100%;
        display: block;
        margin: 20px 0;
        padding: 10px;
        height: 250px;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        input {
            width: 100%;
            position: relative;
            right: 0;
            padding: 5px 10px;
            margin-top: 5px;
        }
    }

`

const ApplicationFormContainer = styled.div`
    background-color: #F9FAFE;
    width: 100%;
`;

const ApplicationFormWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 0 0 40px 0;

    h1 {
        font-size: 2rem;
        margin: 0 0 20px 0px;
        font-weight: 500;
        font-family: 'Rufina', serif;
        text-align: center;
    }

    input[type='submit'] {
        margin: ${props => props.center ? `0 auto` : ``};
        background: #EC7580;
        color: #fff;
        outline: none;
        border: none;
        border-radius: 5px;
        padding: 10px 30px 10px 30px;
        text-align: center;
        font-weight: 600;
        background-repeat: no-repeat;
        background-size: initial;
        background-position: calc(100% - 15px) center;
        display: block;
        margin: 0 auto;

        &:hover {
            background: #e7515f;
            background-position: calc(100% - 15px) center;
            transition: background 0.3s ease-in-out;
            cursor: pointer;
        }
    }

`

const RecruitmentComponent = () => {

    const [activePosition, setActivePosition] = useState(`No Position Selected`);


    return (
        <>
            <Container>
                <Wrapper>
                    <h1>Career Openings</h1>
                    {listings.map((listing, i) => {
                        return (
                            <Card key={`recruitmentCard${i}`}>
                                <p>
                                    <h3>{listing.title}</h3>
                                    <h4>{listing.area}</h4>
                                </p>
                                <Link to="/careers#application"><Button onClick={() => setActivePosition(listing.title)}>Apply</Button></Link>
                            </Card>
                        )
                    })}
                    
                </Wrapper>
            </Container>
            <ApplicationFormContainer>
                <ApplicationFormWrapper>
                    <h1 id="application">Application</h1>
                    <form enctype="multipart/form-data" action="https://getform.io/f/93cfe5ba-25fd-4c68-a46c-a0667fb78292" method="POST">

                        <FormField>
                            Full Name
                <input type="text" name="fullName" required />
                        </FormField>

                        <FormField>
                            Email
                <input type="email" name="email" required />
                        </FormField>

                        <FormField>
                            Phone Number
                <input type="phone" name="phone" />
                        </FormField>

                        <FormField>
                            Resume
                <input name="file" type="file" />
                        </FormField>

                        <FormField>
                            Position
                            <input type="text" name="position" value={activePosition} disabled />
                        </FormField>

                        <FormField>
                            Add message or cover letter
                            <textarea name="coverLetter" />
                        </FormField>

                        <input type="submit" value="Apply for this job" />

                    </form>

                </ApplicationFormWrapper>

            </ApplicationFormContainer>
        </>
    )
}


const listings = [
    { title: `Broadcasting Transmission Technician`, area: `Communications Technology` },
    { title: `Broadcasting Studio Technician`, area: `Communications Technology` },
    { title: `AASTRA (Ericsson) PABX Telephone Technician`, area: `Communications Technology` },
    { title: `Video Conference Technician`, area: `Communications Technology` },
    { title: `Satellite Communication Technician`, area: `Communications Technology` },
    { title: `Two way radio (VHF/UHF and HF) Communication Technician`, area: `Communications Technology` },
    { title: `Tetra Trunking Radio Systems Technician`, area: `Communications Technology` },
    { title: `Microwave Communication Technician`, area: `Communications Technology` },
    { title: `Telecommunication Rigger`, area: `Communications Technology` },
    { title: `Communications Logistics Assistant`, area: `Communications Technology` },
    { title: `Communications Centre Operator`, area: `Communications Technology` },
    { title: `Information Technology Technician`, area: `Information Technology` },
    { title: `Lotus Notes Administrator Technician`, area: `Information Technology` },
    { title: `Local Area Network (LAN) Technician`, area: `Information Technology` },
    { title: `Wide Area Network (WAN) Technician`, area: `Information Technology` },
    { title: `Application Administrator`, area: `Information Technology` },
    { title: `Web Designer`, area: `Information Technology` },
    { title: `Web Developer LINUX`, area: `Information Technology` },
    { title: `UNIX Systems Administrator`, area: `Information Technology` },
    { title: `Information Technology Infrastructure Technician`, area: `Information Technology` },
    { title: `Helpdesk/User Support Technician`, area: `Information Technology` },
    { title: `Data Storage Administrator`, area: `Information Technology` },
    { title: `Software Quality Assurance`, area: `Information Technology` },
    { title: `Geospatial Information Systems (GIS) application developer`, area: `Information Technology` },
    { title: `Alternative Power Technician`, area: `Operations` },
    { title: `Special Equipment Technician`, area: `Operations` },
    { title: `Spectrum Manager Specialist`, area: `Operations` },
    { title: `Information Technology Budget and Administration Assistant`, area: `Operations` },
    { title: `Regional IT Coordinator`, area: `Business Management` },
    { title: `User Support Trainer`, area: `Business Management` },
    { title: `System Analyst`, area: `Business Management` },
    { title: `Business Analyst/Imagery Data Analyst`, area: `Business Management` },
    { title: `Project Leader`, area: `Business Management` },
    { title: `Project Manager`, area: `Business Management` },
];


export default RecruitmentComponent;