import React from "react";
import styled from "styled-components";

import Button from "./button";

const Card = styled.div`
    background-color: #fff;
    width: 49%;
    padding: 20px 20px 70px 20px;
    box-shadow: 0 2px 4px rgba(60,66,68,.25);
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;

    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1.5rem;
        margin: 0 0 20px 0px;
        font-weight: 500;
        font-family: 'Rufina', serif;
        text-align: center;
    }

    button {
        margin: 10px auto;
        position: absolute;
        bottom: 20px;
        left: 0; 
        right: 0;
    }


`;

const CardHolder = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 40px 0;
    background-color: ${props => props.background};
    flex-wrap: wrap;
`

const CardGridContainer = styled.div`
    width: 100%;
    background-color: ${props => props.background};
`

const CardGrid = ({background}) => {
    return (
        <CardGridContainer background={background}>
            <CardHolder>
                <Card>
                    <h2>Communications Technology</h2>
                    <p>
                        <ul>
                            <li>Broadcasting Transmission Technician</li>
                            <li>Broadcasting Studio Technician</li>
                            <li>AASTRA (Ericsson) PABX Telephone Technician</li>
                            <li>Video Conference Technician</li>
                            <li>Satellite Communication Technician</li>
                            <li>Two way radio(VHF/UHF and HF)Communication Technician</li>
                            <li>Tetra Trunking Radio Systems Technician</li>
                            <li>Microwave Communication Technician</li>
                            <li>Telecommunication Rigger</li>
                            <li>Communications Logistics Assistant</li>
                            <li>Communications Centre Operator</li>
                        </ul>
                    </p>
                    <Button>Apply</Button>
                </Card>
                <Card>
                    <h2>Information Technology</h2>
                    <p>
                        <ul>
                            <li>Information Technology Technician</li>
                            <li>Lotus Notes Administrator Technician</li>
                            <li>Lotus Notes Developer Technician</li>
                            <li>Local Area Network (LAN) Technician</li>
                            <li>Wide Area Network (WAN) Technician</li>
                            <li>Application Administrator</li>
                            <li>Web Designer</li>
                            <li>Web Developer LINUX</li>
                            <li>UNIX Systems Administrator</li>
                            <li>Information Technology Infrastructure Technician</li>
                            <li>Helpdesk/User Support Technician</li>
                            <li>Data Storage Administrator</li>
                            <li>Software Quality Assurance</li>
                            <li>Geospatial Information Systems (GIS) application developer</li>
                            <li>Geospatial Information Systems Technician</li>
                        </ul>
                    </p>
                    <Button>Apply</Button>
                </Card>
                <Card>
                    <h2>Operations</h2>
                    <p>
                        <ul>
                            <li>Alternative power technician</li>
                            <li>Special equipment technician</li>
                            <li>Spectrum Manager Specialist</li>
                            <li>Information Technology Budget and Administration Assistant</li>
                        </ul>
                    </p>
                    <Button>Apply</Button>
                </Card>
                <Card>
                    <h2>Business Management</h2>
                    <p>
                        <ul>
                            <li>Regional IT Coordinator</li>
                            <li>User Support Trainer</li>
                            <li>System Analyst</li>
                            <li>Business Analyst/Imagery Data Analyst</li>
                            <li>Project Leader</li>
                            <li>Project Manager</li>
                        </ul>
                    </p>
                    <Button>Apply</Button>
                </Card>
            </CardHolder>
        </CardGridContainer>
    )
}

export default CardGrid;